import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, map, of } from 'rxjs';
import { DetailedCompanyCto } from 'src/app/api/models';
import { ModuleAccessLevel } from 'src/app/api/models/module-access-level';
import { PiSoftwareVersion } from 'src/app/api/models/pi-software-version';
import { CompanyService, UserService } from 'src/app/api/services';

export interface UserMoveToCompanyDialogData {
    userId: string;
    sourceCompanyId: string;
}

export const pi2Modules: string[] = [
    'HireHero',
    'Design2',
    'Diagnose2',
    'Inspire2'
];

@Component({
    selector: 'app-user-move-to-company-dialog',
    templateUrl: './user-move-to-company-dialog.component.html',
    styleUrls: ['./user-move-to-company-dialog.component.scss']
})
export class UserMoveToCompanyDialogComponent implements OnInit {
    public moveUserForm = this.fb.group({
        companyId: [
            '',
            {
                validators: [Validators.required],
                asyncValidators: [this.companyValidatorAsync.bind(this)],
                updateOn: 'blur'
            }
        ],
        deleteExisting: [true]
    });
    sourcePiVersion: PiSoftwareVersion | undefined;
    targetPiVersion: PiSoftwareVersion | undefined;
    pi2Version = PiSoftwareVersion.PI2;
    loading = false;

    constructor(
        private readonly fb: FormBuilder,
        private readonly dialogRef: MatDialogRef<UserMoveToCompanyDialogComponent>,
        private readonly userService: UserService,
        private readonly companyService: CompanyService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: UserMoveToCompanyDialogData
    ) {}

    ngOnInit() {
        this.companyService
            .getCompany$Json({ companyId: this.data.sourceCompanyId })
            .subscribe((detailedCompanyCto) => {
                this.sourcePiVersion =
                    this.getSoftwareVersion(detailedCompanyCto);
            });
    }

    get companyId(): string | undefined {
        return this.moveUserForm.controls.companyId.value ?? undefined;
    }

    get deleteExisting(): boolean | undefined {
        return this.moveUserForm.controls.deleteExisting.value ?? undefined;
    }

    public setCompanyId(companyId: string): void {
        this.moveUserForm.controls.companyId.setValue(companyId);
        this.moveUserForm.controls.companyId.markAsDirty();
        this.moveUserForm.controls.companyId.updateValueAndValidity();
    }

    public move(): void {
        if (this.moveUserForm.valid) {
            this.loading = true;
            this.userService
                .moveUserToCompany$Json({
                    userId: this.data.userId,
                    sourceCompanyId: this.data.sourceCompanyId,
                    sourceCompanyVersion: this.sourcePiVersion!,
                    targetCompanyId: this.companyId!,
                    targetCompanyVersion: this.targetPiVersion!,
                    disablePreviousUserCompany: this.deleteExisting
                })
                .subscribe(() => {
                    this.dialogRef.close();
                    this.loading = false;
                });
        }
    }

    private companyValidatorAsync(control: AbstractControl) {
        const companyId = control.value;
        if (companyId === this.data.sourceCompanyId) {
            return of({ invalidCompanyId: true });
        }
        return this.companyService.getCompany$Json({ companyId }).pipe(
            map((detailedCompanyCto) => {
                this.targetPiVersion =
                    this.getSoftwareVersion(detailedCompanyCto);
                return detailedCompanyCto ? null : { invalidCompanyId: true };
            }),
            catchError((error) => {
                console.error('Error verifying company ID', error);
                return of({ invalidCompanyId: true });
            })
        );
    }

    private getSoftwareVersion(companyCto: DetailedCompanyCto) {
        const smac =
            companyCto.softwareModuleAccessConfiguration
                ?.softwareModuleAccessConfig;
        const companyModules = smac?.filter(
            (x) =>
                !!x.accessLevel &&
                x.accessLevel !== ModuleAccessLevel.NoAccess &&
                pi2Modules.includes(x.name!)
        );
        if (!!companyModules && companyModules?.length > 0) {
            return PiSoftwareVersion.PI2;
        }
        return PiSoftwareVersion.Legacy;
    }
}
