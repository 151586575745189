<h1 mat-dialog-title>Move User To Company</h1>
<div mat-dialog-content>
    <form [formGroup]="moveUserForm" style="height: 130px">
        <mat-grid-list rowHeight="75px" cols="1" gutterSize="5px">
            <mat-grid-tile>
                <div style="align-items: start; display: flex">
                    <mat-form-field appearance="fill">
                        <mat-label>Company GUID</mat-label>
                        <input matInput formControlName="companyId" />
                    </mat-form-field>
                    <app-company-search-input
                        (userSelected)="setCompanyId($event)"
                    ></app-company-search-input>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-checkbox formControlName="deleteExisting"
                    >Disable Existing UserCompany</mat-checkbox
                >
            </mat-grid-tile>
        </mat-grid-list>
    </form>
</div>

<div class="disclaimer-text">
    <div class="company-info" *ngIf="!!moveUserForm.controls.companyId.value">
        <div *ngIf="!moveUserForm.valid; else versionInfo" class="warning">
            The company GUID is not valid. It may not exist, or it is not a
            company you can move to (e.g. it is the same company as the user is
            currently in).
        </div>
        <ng-template #versionInfo>
            {{ 'Moving from ' }}
            <span [ngClass]="sourcePiVersion === pi2Version ? 'pi2' : 'legacy'"
                >{{ sourcePiVersion + ' company' }}
            </span>
            {{ ' to ' }}
            <span [ngClass]="targetPiVersion === pi2Version ? 'pi2' : 'legacy'">
                {{ targetPiVersion + ' company' }}
            </span>
            {{ ', includes: ' }}
            <ul class="disclaimer-text-list">
                <li class="legacy">User SQL records</li>
                <li
                    *ngIf="
                        sourcePiVersion !== pi2Version &&
                        targetPiVersion === pi2Version
                    "
                    class="pi2"
                >
                    Permissions entities (newly created for PI2)
                </li>
                <li
                    *ngIf="
                        sourcePiVersion === pi2Version &&
                        targetPiVersion === pi2Version
                    "
                    class="pi2"
                >
                    Permissions entities
                </li>
            </ul>
            It does NOT include:
            <ul class="disclaimer-text-list">
                <li class="warning">User Survey records - both BA and CA</li>
            </ul>
            <div>
                To move User Survey records,
                <a href="/userops/storedprocedures/moveperson"
                    >move the associated Person</a
                >
                for this User on completion of "Move User"
            </div>
        </ng-template>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
    <button
        mat-button
        [disabled]="!moveUserForm.valid || !moveUserForm.dirty || loading"
        (click)="move()"
    >
        Move User
    </button>
    <mat-spinner *ngIf="loading"></mat-spinner>
</div>
