<button
    mat-stroked-button
    color="primary"
    (click)="back()"
    *ngIf="company"
    style="margin-bottom: 8px"
>
    Back
</button>

<app-company-search
    *ngIf="!company"
    selectionType="guid"
    (companySelected)="selectCompany($event)"
></app-company-search>

<mat-spinner *ngIf="loading"></mat-spinner>
<div *ngIf="!loading && company">
    <mat-card appearance="outlined">
        <mat-card-header>
            <mat-card-title
                >Company: {{ company.company?.name }}
            </mat-card-title>
        </mat-card-header>

        <mat-divider
            style="position: relative; margin: 25px 0 25px 0"
        ></mat-divider>

        <mat-card-content>
            <form [formGroup]="editCompanyForm">
                <mat-grid-list
                    cols="3"
                    rowHeight="100px"
                    style="max-width: 1200px"
                >
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Account Status</mat-label>
                            <input matInput formControlName="accountStatus" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Anonymization Status</mat-label>
                            <mat-select formControlName="anonymizationStatus">
                                <mat-option [value]="1"
                                    >Not Applicable</mat-option
                                >
                                <mat-option [value]="2"
                                    >Anonymization Requested</mat-option
                                >
                                <mat-option [value]="3" [disabled]="true"
                                    >Anonymization Completed</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Company ID</mat-label>
                            <input matInput formControlName="companyId" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="companyId"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Company GUID</mat-label>
                            <input matInput formControlName="companyGuid" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="companyGuid"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>API Company ID</mat-label>
                            <input matInput formControlName="apiCompanyId" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="apiCompanyId"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div>
                            <mat-label>Is Deleted</mat-label>&nbsp;
                            <mat-slide-toggle
                                formControlName="isDeleted"
                            ></mat-slide-toggle>
                            <br />
                            <mat-label>Is Active In 15X</mat-label>&nbsp;
                            <mat-slide-toggle
                                formControlName="isActiveIn15X"
                            ></mat-slide-toggle>
                            <br />
                            <mat-label>Is Account Paused</mat-label>&nbsp;
                            <mat-slide-toggle
                                formControlName="isPaused"
                            ></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div>
                            <mat-label>Is MFA Enabled</mat-label>&nbsp;
                            <mat-slide-toggle
                                formControlName="isMFAEnabled"
                            ></mat-slide-toggle>
                            <br />
                            <mat-label>Is On Hold</mat-label>&nbsp;
                            <mat-slide-toggle
                                formControlName="isOnHold"
                            ></mat-slide-toggle>
                            <br />
                            <mat-label>Is In Good Standing</mat-label>&nbsp;
                            <mat-slide-toggle
                                formControlName="isInGoodStanding"
                            ></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    @if(company.company?.integrationSettings){
                        <mat-grid-tile>
                            <div>
                                <mat-label>Has API Access</mat-label>&nbsp;
                                <mat-slide-toggle
                                    formControlName="hasApiAccess"
                                ></mat-slide-toggle>
                                <br />
                                <mat-label>Has ATS Access</mat-label>&nbsp;
                                <mat-slide-toggle
                                    formControlName="hasAtsAccess"
                                ></mat-slide-toggle>
                                <br />
                                <mat-label>Has HRIS Access</mat-label>&nbsp;
                                <mat-slide-toggle
                                    formControlName="hasHrisAccess"
                                ></mat-slide-toggle>
                            </div>
                        </mat-grid-tile>
                    }
                </mat-grid-list>
            </form>
        </mat-card-content>
        <mat-card-actions *ngIf="canEdit">
            <button
                mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="!(editCompanyForm.valid && editCompanyForm.dirty)"
            >
                Save
            </button>
            <button
                mat-raised-button
                (click)="reset()"
                [disabled]="!(editCompanyForm.valid && editCompanyForm.dirty)"
            >
                Reset
            </button>
        </mat-card-actions>
    </mat-card>

    <div style="margin-top: 5px">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    [Read-Only]: Software Module Access Configuration
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-divider
                style="position: relative; margin: 0 0 25px 0"
            ></mat-divider>

            <div *ngIf="company.softwareModuleAccessConfiguration">
                <mat-grid-list
                    cols="3"
                    rowHeight="75px"
                    style="max-width: 1100px"
                >
                    <mat-grid-tile>
                        <div>
                            <mat-label>Is Deleted</mat-label>&nbsp;
                            <mat-slide-toggle
                                [checked]="
                                    company.softwareModuleAccessConfiguration
                                        .isDeleted
                                "
                                [disabled]="true"
                            ></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div>
                            <mat-label>Is Anonymized</mat-label>&nbsp;
                            <mat-slide-toggle
                                [checked]="
                                    company.softwareModuleAccessConfiguration
                                        .isAnonymized
                                "
                                [disabled]="true"
                            ></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div>
                            <mat-label>Account Classification: </mat-label>
                            {{
                                company.softwareModuleAccessConfiguration
                                    .classificationType
                            }}
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>

                <table
                    mat-table
                    [dataSource]="smac"
                    class="mat-elevation-z8"
                    *ngIf="
                        company.softwareModuleAccessConfiguration
                            .softwareModuleAccessConfig
                    "
                    style="width: 100%"
                >
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Module Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="accessLevel">
                        <th mat-header-cell *matHeaderCellDef>Access Level</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.accessLevel }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="experienceType">
                        <th mat-header-cell *matHeaderCellDef>
                            Experience Type
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.experienceType }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="accountType">
                        <th mat-header-cell *matHeaderCellDef>Account Type</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.accountType }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                </table>

                <ng-container
                    *ngIf="
                        !company.softwareModuleAccessConfiguration
                            .softwareModuleAccessConfig
                    "
                >
                    Module Access Configuration not found.
                </ng-container>
            </div>
            <div *ngIf="!company.softwareModuleAccessConfiguration">
                Not found.
            </div>
        </mat-expansion-panel>
    </div>
    <div style="margin-top: 5px">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title> Company Time Trial </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-divider
                style="position: relative; margin: 0 0 25px 0"
            ></mat-divider>
            <p style="position: relative; margin: 0 0 15px 15px">
                Note: All times are UTC format
            </p>

            <div>
                <table
                    mat-table
                    [dataSource]="timeTrialsDataSource"
                    *ngIf="timeTrials"
                    class="mat-elevation-z8"
                    style="width: 100%"
                >
                    <ng-container matColumnDef="timeTrialId">
                        <th mat-header-cell *matHeaderCellDef>Time Trial ID</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.timeTrialId }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef>Product</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.product }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef>Start Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.startDate }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef>End Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.endDate }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="durationDays">
                        <th mat-header-cell *matHeaderCellDef>Duration Days</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.durationDays }}
                        </td>
                    </ng-container>
                    <tr
                        mat-header-row
                        *matHeaderRowDef="timeTrialDisplayedColumns"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: timeTrialDisplayedColumns"
                    ></tr>
                </table>
            </div>
        </mat-expansion-panel>
    </div>
    <div style="margin-top: 5px; margin-bottom: 25px">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Legacy -> PI2 Upgrade & PI2 Data Correction
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-divider
                style="position: relative; margin: 0 0 25px 0"
            ></mat-divider>

            <div *ngIf="canTriggerCompanyUpgrade" style="margin-bottom: 15px">
                <button
                    mat-raised-button
                    color="primary"
                    (click)="executeDataCorrection()"
                >
                    Execute PI2 Data Correction
                </button>
            </div>

            <p style="position: relative; margin: 0 0 15px 15px">
                Note: All times are UTC format
            </p>

            <div>
                <table
                    mat-table
                    [dataSource]="upgradeDetailsDataSource"
                    *ngIf="timeTrials"
                    class="mat-elevation-z8"
                    style="width: 100%"
                >
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.status }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef>Start Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.startTime }}
                        </td>
                    </ng-container>
                    <tr
                        mat-header-row
                        *matHeaderRowDef="upgradeDetailsDisplayedColumns"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="
                            let row;
                            columns: upgradeDetailsDisplayedColumns
                        "
                    ></tr>
                </table>
            </div>
        </mat-expansion-panel>
    </div>
</div>
