<button
    mat-stroked-button
    color="primary"
    (click)="back()"
    *ngIf="assessment"
    style="margin-bottom: 8px"
>
    Back
</button>

<app-behavioral-assessment-search
    *ngIf="!assessment"
    selectionType="guid"
    (userSelected)="selectAssessment($event)"
></app-behavioral-assessment-search>

<mat-spinner *ngIf="loading"></mat-spinner>
<div *ngIf="!loading && assessment">
    <mat-card appearance="outlined">
        <mat-card-header>
            <mat-card-title
                >Behavioral Assessment: {{ assessment.surveyUser?.firstName }}
                {{ assessment.surveyUser?.lastName }}
            </mat-card-title>
        </mat-card-header>

        <mat-divider
            style="position: relative; margin: 25px 0 25px 0"
        ></mat-divider>

        <mat-card-content>
            <form [formGroup]="editAssessmentForm">
                <mat-grid-list
                    cols="3"
                    rowHeight="75px"
                    style="max-width: 1200px"
                    gutterSize="8px"
                >
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="email"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>First Name</mat-label>
                            <input matInput formControlName="firstName" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Last Name</mat-label>
                            <input matInput formControlName="lastName" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Survey User ID</mat-label>
                            <input matInput formControlName="surveyUserId" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="surveyUserId"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Survey User GUID</mat-label>
                            <input matInput formControlName="surveyUserGuid" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="surveyUserGuid"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Survey Session Key</mat-label>
                            <input matInput formControlName="assessmentGuid" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="assessmentGuid"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>BSID</mat-label>
                            <input matInput formControlName="bsId" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="bsId"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="2"></mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Analyst Email</mat-label>
                            <input matInput formControlName="analystEmail" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="analystEmail"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Analyst First name</mat-label>
                            <input
                                matInput
                                formControlName="analystFirstName"
                            />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Analyst Last Name</mat-label>
                            <input matInput formControlName="analystLastName" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Primary Company Name</mat-label>
                            <input matInput formControlName="companyName" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Primary Company ID</mat-label>
                            <input matInput formControlName="companyId" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="companyId"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Primary Company GUID</mat-label>
                            <input matInput formControlName="companyGuid" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="companyGuid"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Assessment Date</mat-label>
                            <input matInput formControlName="assessmentDate" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="assessmentDate"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile rowspan="2">
                        <div>
                            <mat-label>Is Session Deleted</mat-label>&nbsp;
                            <mat-slide-toggle
                                formControlName="isDeleted"
                            ></mat-slide-toggle>
                            <br />
                            <mat-label>Is Survey Deleted</mat-label>&nbsp;
                            <mat-slide-toggle
                                formControlName="isPISurveyDeleted"
                            ></mat-slide-toggle>
                            <br />
                            <mat-label>Is Survey User Deleted</mat-label>&nbsp;
                            <mat-slide-toggle
                                formControlName="isSurveyUserDeleted"
                            ></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </form>
        </mat-card-content>
        <mat-card-actions *ngIf="canEdit">
            <button
                mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="
                    !(editAssessmentForm.valid && editAssessmentForm.dirty)
                "
            >
                Save
            </button>
            <button
                mat-raised-button
                (click)="reset()"
                [disabled]="
                    !(editAssessmentForm.valid && editAssessmentForm.dirty)
                "
            >
                Reset
            </button>
        </mat-card-actions>
    </mat-card>
</div>
