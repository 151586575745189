<button
    mat-stroked-button
    color="primary"
    (click)="back()"
    *ngIf="user"
    style="margin-bottom: 8px"
>
    Back
</button>

<app-user-search
    *ngIf="!user"
    selectionType="guid"
    (userSelected)="selectUser($event)"
></app-user-search>

<mat-spinner *ngIf="loading"></mat-spinner>
<div *ngIf="!loading && user">
    <mat-card appearance="outlined">
        <mat-card-header>
            <mat-card-title
                >User: {{ user.user?.firstName }}
                {{ user.user?.lastName }}
                <button
                    mat-raised-button
                    color="accent"
                    [matMenuTriggerFor]="menu"
                    *ngIf="canEdit"
                >
                    Actions
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="moveUserToCompany()">
                        Move User To Another Company
                    </button>
                </mat-menu>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <form [formGroup]="editUserForm">
                <mat-grid-list
                    cols="3"
                    rowHeight="75px"
                    style="max-width: 1200px"
                >
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="email"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>First Name</mat-label>
                            <input matInput formControlName="firstName" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Last Name</mat-label>
                            <input matInput formControlName="lastName" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>User ID</mat-label>
                            <input matInput formControlName="userId" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="userId"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>User GUID</mat-label>
                            <input matInput formControlName="userGuid" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="userGuid"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>API User ID</mat-label>
                            <input matInput formControlName="apiUserId" />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="apiUserId"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Last Login Time</mat-label>
                            <input matInput formControlName="lastLoginTime" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Authentication ID</mat-label>
                            <input
                                matInput
                                formControlName="authenticationId"
                            />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="authenticationId"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Primary Company Name</mat-label>
                            <input
                                matInput
                                formControlName="primaryCompanyName"
                            />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Primary Company ID</mat-label>
                            <input
                                matInput
                                formControlName="primaryCompanyId"
                            />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="primaryCompanyId"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="2">
                        <mat-form-field appearance="fill">
                            <mat-label>Primary Company GUID</mat-label>
                            <input
                                matInput
                                formControlName="primaryCompanyGuid"
                            />
                        </mat-form-field>
                        <app-copy-to-clipboard
                            [content]="primaryCompanyGuid"
                        ></app-copy-to-clipboard>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-label>Is Deleted</mat-label>&nbsp;
                        <mat-slide-toggle
                            formControlName="isDeleted"
                        ></mat-slide-toggle>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-label>Is Retired</mat-label>&nbsp;
                        <mat-slide-toggle
                            formControlName="isRetired"
                        ></mat-slide-toggle>
                    </mat-grid-tile>
                </mat-grid-list>
                <ng-container formArrayName="userCompanies">
                    <table
                        mat-table
                        [dataSource]="userCompanies.controls"
                        class="mat-elevation-z8"
                        *ngIf="user.userCompanies?.length ?? 0 > 0"
                        style="width: 100%"
                    >
                        <ng-container matColumnDef="isDeleted">
                            <th mat-header-cell *matHeaderCellDef>
                                Is Deleted
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [formGroup]="element"
                            >
                                <mat-slide-toggle
                                    formControlName="isDeleted"
                                ></mat-slide-toggle>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="companyName">
                            <th mat-header-cell *matHeaderCellDef>
                                Company Name
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [formGroup]="element"
                            >
                                {{ element.get('companyName').value }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="companyId">
                            <th mat-header-cell *matHeaderCellDef>
                                Company ID
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [formGroup]="element"
                            >
                                {{ element.get('companyId').value }}
                                <app-copy-to-clipboard
                                    [content]="element.get('companyId').value"
                                    type="circle"
                                ></app-copy-to-clipboard>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="companyGuid">
                            <th mat-header-cell *matHeaderCellDef>
                                Company Guid
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [formGroup]="element"
                            >
                                {{ element.get('companyGuid').value }}
                                <app-copy-to-clipboard
                                    [content]="element.get('companyGuid').value"
                                    type="circle"
                                ></app-copy-to-clipboard>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="companyActiveIn15x">
                            <th mat-header-cell *matHeaderCellDef>
                                Company ActiveIn15x
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [formGroup]="element"
                            >
                                {{ element.get('companyActiveIn15x').value }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef>Role</th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [formGroup]="element"
                            >
                                {{ element.get('role').value }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="apiUserCompanyId">
                            <th mat-header-cell *matHeaderCellDef>
                                API User Company ID
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [formGroup]="element"
                            >
                                {{ element.get('apiUserCompanyId').value }}
                                <app-copy-to-clipboard
                                    [content]="
                                        element.get('apiUserCompanyId').value
                                    "
                                    type="circle"
                                ></app-copy-to-clipboard>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="userPermissions">
                            <th mat-header-cell *matHeaderCellDef>
                                Permissions
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let element"
                                [formGroup]="element"
                            >
                                <button
                                    mat-stroked-button
                                    (click)="openPermissionsDialog(element)"
                                >
                                    View
                                </button>
                            </td>
                        </ng-container>
                        <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumns"
                        ></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: displayedColumns"
                        ></tr>
                    </table>
                </ng-container>
            </form>
        </mat-card-content>
        <mat-card-actions *ngIf="canEdit">
            <button
                mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="!(editUserForm.valid && editUserForm.dirty)"
            >
                Save
            </button>
            <button
                mat-raised-button
                (click)="reset()"
                [disabled]="!(editUserForm.valid && editUserForm.dirty)"
            >
                Reset
            </button>
        </mat-card-actions>
    </mat-card>
</div>
