import { Component, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
    CognitiveAccessLevel,
    CompanyUserModuleAccessPermissionsCto,
    PermissionsModuleAccessLevel,
    UserUpdateContextCto,
    UserUpdateRequestCto,
    UserUpdateUserCompanyCto
} from 'src/app/api/models';
import { UserService } from 'src/app/api/services';
import { NotificationService } from 'src/app/shared/services/notification.service';
import {
    Permissions,
    PermissionsService
} from 'src/app/shared/services/permissions.service';
import { EditUserPermissionsDialogComponent } from '../edit-user-permissions-dialog/edit-user-permissions-dialog.component';
import {
    UserMoveToCompanyDialogComponent,
    UserMoveToCompanyDialogData
} from '../user-move-to-company-dialog/user-move-to-company-dialog.component';

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
    editUserForm: FormGroup;

    public canEdit = false;

    public user?: UserUpdateContextCto;

    public loading = false;

    public displayedColumns: string[] = [
        'isDeleted',
        'companyName',
        'role',
        'companyId',
        'companyGuid',
        'companyActiveIn15x',
        'apiUserCompanyId',
        'userPermissions'
    ];

    private selectedId = '';

    constructor(
        private readonly fb: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly userService: UserService,
        permissionsService: PermissionsService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly notificationService: NotificationService
    ) {
        this.canEdit = permissionsService.hasPermission(
            Permissions.UserOpsWrite
        );

        this.editUserForm = this.fb.group({
            email: [
                { value: null, disabled: true },
                [Validators.required, Validators.email]
            ],
            firstName: [{ value: null, disabled: true }, Validators.required],
            lastName: [{ value: null, disabled: true }, Validators.required],
            userId: [{ value: null, disabled: true }],
            userGuid: [{ value: null, disabled: true }],
            apiUserId: [
                { value: null, disabled: !this.canEdit },
                Validators.required
            ],
            lastLoginTime: [{ value: null, disabled: true }],
            authenticationId: [
                { value: null, disabled: true },
                Validators.required
            ],
            primaryCompanyName: [{ value: null, disabled: true }],
            primaryCompanyId: [
                {
                    value: null,
                    disabled: true
                }
            ],
            primaryCompanyGuid: [
                {
                    value: null,
                    disabled: true
                }
            ],
            isDeleted: [{ value: null, disabled: !this.canEdit }],
            isRetired: [{ value: null, disabled: !this.canEdit }],
            userCompanies: this.fb.array([])
        });
    }

    get email(): string | undefined {
        return this.editUserForm.controls['email'].value ?? undefined;
    }

    get apiUserId(): string | undefined {
        return this.editUserForm.controls['apiUserId'].value ?? undefined;
    }

    get primaryCompanyId(): string | undefined {
        return (
            this.editUserForm.controls['primaryCompanyId'].value ?? undefined
        );
    }

    get primaryCompanyGuid(): string | undefined {
        return (
            this.editUserForm.controls['primaryCompanyGuid'].value ?? undefined
        );
    }

    get authenticationId(): string | undefined {
        return (
            this.editUserForm.controls['authenticationId'].value ?? undefined
        );
    }

    get userId(): string | undefined {
        return this.editUserForm.controls['userId'].value ?? undefined;
    }
    get userGuid(): string | undefined {
        return this.editUserForm.controls['userGuid'].value ?? undefined;
    }
    get isDeleted(): boolean | undefined {
        return this.editUserForm.controls['isDeleted'].value ?? undefined;
    }

    get isRetired(): boolean | undefined {
        return this.editUserForm.controls['isRetired'].value ?? undefined;
    }

    get userCompanies(): FormArray {
        return this.editUserForm.get('userCompanies') as FormArray;
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.selectedId = params['id'];
            if (this.selectedId) {
                this.populateForm();
            }
        });
    }

    public save(): void {
        if (this.editUserForm.valid) {
            this.userService
                .updateUser$Json({
                    userId: this.selectedId,
                    body: {
                        id: this.selectedId,
                        apiUserId: this.apiUserId,
                        isDeleted: this.isDeleted,
                        isRetired: this.isRetired,
                        userCompanies: this.userCompanies.controls.reduce(
                            (result, group) => {
                                if (group.dirty) {
                                    result.push({
                                        id: group.get('userCompanyId')?.value,
                                        isDeleted: group.get('isDeleted')?.value
                                    });
                                }

                                return result;
                            },
                            new Array<UserUpdateUserCompanyCto>()
                        )
                    }
                })
                .subscribe({
                    next: (user) => {
                        this.resetForm(user);
                        this.notificationService.showSuccessNotification(
                            'Save Successful'
                        );
                    },
                    error: (errorMessage) => {
                        this.notificationService.showErrorNotification(
                            'Error Saving: ' + errorMessage
                        );
                    }
                });
        }
    }

    public back(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
        this.user = undefined;
    }

    public reset(): void {
        this.populateForm();
    }

    public selectUser(id: string): void {
        this.selectedId = id;
        this.router.navigate([this.selectedId], { relativeTo: this.route });
    }

    public moveUserToCompany(): void {
        const dialogRef = this.dialog.open(UserMoveToCompanyDialogComponent, {
            data: {
                userId: this.user?.user?.userGuid,
                sourceCompanyId: this.primaryCompanyGuid
            } as UserMoveToCompanyDialogData,
            width: '500px'
        });

        dialogRef.afterClosed().subscribe(() => this.reset());
    }

    public openPermissionsDialog(control: AbstractControl): void {
        const dialogRef = this.dialog.open(EditUserPermissionsDialogComponent, {
            data: {
                userGuid: this.userGuid,
                organization: {
                    name: control.value.companyName,
                    id: control.value.companyGuid
                },
                permissions: control.value.userPermissions
            },
            width: '900px'
        });

        dialogRef
            .afterClosed()
            .subscribe((response: UserUpdateRequestCto) =>
                this.updatePermissions(control, response)
            );
    }

    private updatePermissions(
        control: AbstractControl,
        response: UserUpdateRequestCto
    ): void {
        if (response) {
            const userPermissions = control.value
                .userPermissions as CompanyUserModuleAccessPermissionsCto;

            userPermissions.isCompanyAdmin = response.isOrgAdmin;

            if (response.isCogSender) {
                userPermissions.cognitiveAccessLevel =
                    CognitiveAccessLevel.Send;
            }

            if (response.isCogAdmin) {
                userPermissions.cognitiveAccessLevel =
                    CognitiveAccessLevel.Admin;
            }

            if (!response.isCogAdmin && !response.isCogSender) {
                userPermissions.cognitiveAccessLevel =
                    CognitiveAccessLevel.NoAccess;
            }

            const accessLevelUpdates: Record<
                string,
                PermissionsModuleAccessLevel
            > = {};
            const vmAccessUpdates: Record<string, boolean> = {};

            if (response.moduleUpdates) {
                for (const update of response.moduleUpdates) {
                    accessLevelUpdates[update.module as string] =
                        update.accessLevel as PermissionsModuleAccessLevel;
                    vmAccessUpdates[update.module as string] =
                        update.canSpendVMs as boolean;
                }
            }

            userPermissions.accessLevel = {
                ...userPermissions.accessLevel,
                ...accessLevelUpdates
            };

            userPermissions.vmAccess = {
                ...userPermissions.vmAccess,
                ...vmAccessUpdates
            };

            control.setValue(control.value);
        }
    }

    private populateForm(): void {
        this.loading = true;
        this.userService
            .getUser$Json({ userId: this.selectedId })
            .subscribe((user) => this.resetForm(user));
    }

    private resetForm(user: UserUpdateContextCto): void {
        this.user = user;
        this.editUserForm.reset({
            email: this.user.user?.email,
            firstName: this.user.user?.firstName,
            lastName: this.user.user?.lastName,
            userId: this.user.user?.userId,
            userGuid: this.user.user?.userGuid,
            apiUserId: this.user.user?.apiUserId,
            lastLoginTime: this.user.user?.lastLoginTime,
            authenticationID: this.user.user?.authenticationID,
            primaryCompanyName: this.user.user?.primaryCompany?.name,
            primaryCompanyId: this.user.user?.primaryCompany?.companyId,
            primaryCompanyGuid: this.user.user?.primaryCompany?.companyGuid,
            isDeleted: this.user.user?.isDeleted,
            isRetired: this.user.user?.isRetired,
            userCompanies: this.fb.array([])
        });
        this.editUserForm.setControl(
            'userCompanies',
            new FormArray(
                user.userCompanies?.map((x) => {
                    const group = new FormGroup({});
                    group.addControl('isDeleted', new FormControl(x.isDeleted));
                    group.addControl(
                        'companyName',
                        new FormControl(x.company?.name)
                    );
                    group.addControl(
                        'companyId',
                        new FormControl(x.company?.companyId)
                    );
                    group.addControl(
                        'companyGuid',
                        new FormControl(x.company?.companyGuid)
                    );
                    group.addControl(
                        'companyActiveIn15x',
                        new FormControl(x.company?.isActiveIn15X)
                    );
                    group.addControl('role', new FormControl(x.role));
                    group.addControl(
                        'userCompanyId',
                        new FormControl(x.userCompanyId)
                    );
                    group.addControl(
                        'apiUserCompanyId',
                        new FormControl(x.apiUserCompanyId)
                    );
                    group.addControl(
                        'userPermissions',
                        new FormControl(x.userPermissions)
                    );
                    return group;
                }) ?? new Array<FormGroup>()
            )
        );
        this.editUserForm.markAsPristine();
        this.editUserForm.markAsUntouched();
        this.loading = false;
    }
}
