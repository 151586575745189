<h1 mat-dialog-title class="dialog-title">
    User Permissions for {{ organizationName }}
</h1>
<form
    [formGroup]="editPermissionsForm"
    (submit)="saveChanges()"
    *ngIf="!loading; else loadingState"
>
    <div mat-dialog-content>
        <table class="mat-mdc-table edit-permissions-table">
            <thead role="rowgroup">
                <tr class="mat-mdc-header-row">
                    <th class="mat-mdc-header-cell column-module">Module</th>
                    <th class="mat-mdc-header-cell">Access Level</th>
                    <th class="mat-mdc-header-cell">VM Access</th>
                    <th class="mat-mdc-header-cell">Other Access</th>
                </tr>
            </thead>
            <tbody role="rowgroup">
                <tr class="mat-mdc-row" formGroupName="design">
                    <td class="mat-mdc-cell">Design</td>
                    <td class="mat-mdc-cell">
                        <mat-select
                            formControlName="accessLevel"
                            class="access-level-select"
                        >
                            <ng-container
                                *ngFor="
                                    let accessLevelOption of accessLevelOptions
                                "
                            >
                                <mat-option
                                    *ngIf="
                                        accessLevelOption.displayModules
                                            | includes: 'design'
                                    "
                                    [value]="accessLevelOption.value"
                                >
                                    {{ accessLevelOption.name }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </td>
                    <td class="mat-mdc-cell">
                        <mat-checkbox formControlName="vmAccess">
                            Can activate Teams
                        </mat-checkbox>
                    </td>
                    <td class="mat-mdc-cell"></td>
                </tr>
                <tr class="mat-mdc-row" formGroupName="diagnose">
                    <td class="mat-mdc-cell">Diagnose</td>
                    <td class="mat-mdc-cell">
                        <mat-select
                            formControlName="accessLevel"
                            class="access-level-select"
                        >
                            <ng-container
                                *ngFor="
                                    let accessLevelOption of accessLevelOptions
                                "
                            >
                                <mat-option
                                    *ngIf="
                                        accessLevelOption.displayModules
                                            | includes: 'diagnose'
                                    "
                                    [value]="accessLevelOption.value"
                                >
                                    {{ accessLevelOption.name }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </td>
                    <td class="mat-mdc-cell">
                        <mat-checkbox formControlName="vmAccess">
                            Can activate (premium insights)
                        </mat-checkbox>
                    </td>
                    <td class="mat-mdc-cell"></td>
                </tr>
                <tr class="mat-mdc-row" formGroupName="hire">
                    <td class="mat-mdc-cell">Hire</td>
                    <td class="mat-mdc-cell">
                        <mat-select
                            formControlName="accessLevel"
                            class="access-level-select"
                        >
                            <ng-container
                                *ngFor="
                                    let accessLevelOption of accessLevelOptions
                                "
                            >
                                <mat-option
                                    *ngIf="
                                        accessLevelOption.displayModules
                                            | includes: 'hire'
                                    "
                                    [value]="accessLevelOption.value"
                                >
                                    {{ accessLevelOption.name }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </td>
                    <td class="mat-mdc-cell">
                        <mat-checkbox formControlName="vmAccess">
                            Can redeem credits
                        </mat-checkbox>
                    </td>
                    <td class="mat-mdc-cell">
                        <mat-checkbox formControlName="isCogSender">
                            Has cognitive access
                        </mat-checkbox>
                    </td>
                </tr>
                <tr class="mat-mdc-row" formGroupName="inspire">
                    <td class="mat-mdc-cell">Inspire</td>
                    <td class="mat-mdc-cell">
                        <mat-select
                            formControlName="accessLevel"
                            class="access-level-select"
                        >
                            <ng-container
                                *ngFor="
                                    let accessLevelOption of accessLevelOptions
                                "
                            >
                                <mat-option
                                    *ngIf="
                                        accessLevelOption.displayModules
                                            | includes: 'inspire'
                                    "
                                    [value]="accessLevelOption.value"
                                >
                                    {{ accessLevelOption.name }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </td>
                    <td class="mat-mdc-cell">
                        <mat-checkbox formControlName="vmAccess">
                            Can invite co-workers
                        </mat-checkbox>
                    </td>
                    <td class="mat-mdc-cell"></td>
                </tr>
                <tr class="mat-mdc-row" formGroupName="perform">
                  <td class="mat-mdc-cell">Perform</td>
                  <td class="mat-mdc-cell">
                    <mat-select
                      formControlName="accessLevel"
                      class="access-level-select"
                    >
                      <ng-container
                        *ngFor="
                                    let accessLevelOption of accessLevelOptions
                                "
                      >
                        <mat-option
                          *ngIf="
                                        accessLevelOption.displayModules
                                            | includes: 'perform'
                                    "
                          [value]="accessLevelOption.value"
                        >
                          {{ accessLevelOption.name }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </td>
                  <td class="mat-mdc-cell"></td>
                  <td class="mat-mdc-cell"></td>
                </tr>
            </tbody>
        </table>
        <div class="global-admin-options">
            <mat-checkbox formControlName="isOrgAdmin">
                <div class="checkbox-label">
                    <div>Make Organization Admin</div>
                    <div *ngIf="isHireFree" class="helper-text">
                        (Since the account is a free HireHero account, removing
                        this will also remove Cog Admin + Sender for the user.)
                    </div>
                </div>
            </mat-checkbox>
            <div>
                Organization Admins can configure organization-level settings
                and view billing details
            </div>
        </div>
        <div class="global-admin-options">
            <mat-checkbox formControlName="isCogAdmin">
                Make Cognitive Admin
            </mat-checkbox>
            <div>
                Cognitive Admins can change Cognitive access for other users
            </div>
            <div>
                NOTE: User must be an Organization admin to be granted Cognitive 
                Admin access
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="dialog-actions">
        <button mat-button mat-dialog-close cdkFocusInitial type="button">
            Cancel
        </button>
        <ng-container *ngIf="canEdit">
            <button
                mat-flat-button
                color="primary"
                [disabled]="!pendingChanges || saving"
                type="submit"
            >
                <mat-icon *ngIf="saving">
                    <mat-spinner diameter="23" color="accent"></mat-spinner>
                </mat-icon>
                Edit Permissions
            </button>
        </ng-container>
    </div>
</form>
<ng-template #loadingState>
    <div class="loading-container">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
